import React from 'react'
import { v4 as uuidv4} from 'uuid';
import { Link } from 'react-router-dom';

const FallPrograms = ({fallPrograms}) => {
    const programsRows = [];
    const programsModals = [];
    fallPrograms.forEach(program => {
        let id = uuidv4();
        let color = "grey lighten-5";

        programsRows.push(
            <tr key={id} className={color}>
                <td><a href={`${program.registrationURL}`} className='register-program-name' target='_blank' rel='noreferrer'>{program.name}</a></td>
                <td><a href={`#${id}-modal`} className='btn-small blue darken-2 modal-trigger right'>DETAILS</a></td>
            </tr>
        )

        programsModals.push(
            <div id={`${id}-modal`} key={`${id}-modal`} className="modal">
                <div className="modal-content">
                    <h4 className='center register-program-name-modal'>{program.name}</h4>
                    <div className='program-details-div'>
                        <p><span className='program-description-attribute'>AGES</span>: {program.ages}</p>
                        <p><span className='program-description-attribute'>DATES</span>: {program.dates}</p>
                        <p><span className='program-description-attribute'>TIME</span>: {program.time}</p>
                        <p><span className='program-description-attribute'>LOCATION</span>: {program.location}</p>
                        <p><span className='program-description-attribute'>COST</span>: {program.cost}</p>
                        <p><span className='program-description-attribute'>DESCRIPTION</span>: {program.description}</p>
                    </div>
                    <div className='center'>
                        <Link to={program.registrationURL} target='_blank'><button className='btn-large red register-btn-modal'>REGISTER</button></Link>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect waves-green btn-flat">Exit</a>
                </div>
            </div>
        )
    })



  return (
    <div id='fall-programs-list'>
        <table>
            <thead></thead>
            <tbody>
                {programsRows}
            </tbody>
        </table>
        {programsModals}
    </div>
  )
}

export default FallPrograms